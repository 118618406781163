.booster {
}

.booster__wrap {
    margin-top: 40px;
}
.boosters_swiper {
    padding: 0 !important;
}

.booster__slider__bullets {
    display: flex;
    margin: 20px 0;
}

.booster__slider__bullets > * {
    display: block;
    width: 10px;
    height: 10px;
    background: #4596f7;
    margin: 0 6px;
    border-radius: 50%;
}

.booster__btns {
    display: flex;
    justify-content: center;
    gap: 13px;
    margin-top: 10px;
}

.booster__btns__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    gap: 13px;
}

.booster__btns__wrap > button {
    min-width: 161px;
    height: 43px;
}

.booster__btns__wrap > button:nth-child(3) {
    grid-column: 1 / -1;
    border-radius: 6px;
    height: 42.5px;
}

.booster__price {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booster__price__table {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.booster__price-count {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #c5c0da;
    margin: 20px 0;
}

.booster__price__table-item {
    margin: 6px 0;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #c5c0da;
    cursor: pointer;
    border-radius: 10px;
    padding: 0 5px;
}

.booster__price__table-logowrap {
    display: flex;
    align-items: center;
}

.booster__price__table-item > img {
    width: 30px;
    height: 30px;
}

.booster__price__line {
    border: 1px solid #1c225c;
}

.booster__message {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    gap: 20px;
}

.booster__message > p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-align: center;
}

.booster__message > h3 {
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.booster__slider {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booster__slider__block {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    min-width: 320px;
    /* transform: translateX(5px); */
}

.slide__block {
    padding-top: 40px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}

.swiper-pagination {
    height: 20px;
    position: fixed;
    top: 325px;
    z-index: 1;
}

.swiper-pagination:last-child {
}

.swiper-pagination:last-child:active {
}

.swiper-pagination-bullet {
    background-color: #4596f7;
    width: 9px !important;
    height: 9px !important;
}

.bullet_last {
    border-radius: 50%;
    background: #0f0230;
    position: relative;
}

.bullet_last > svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #0f0230;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #4596f7 !important;
    width: 11px !important;
    height: 11px !important;
}

.near-active {
    background-color: #4596f7 !important;
    opacity: 0.6 !important;
    width: 10px !important;
    height: 10px !important;
}

.swiper {
    padding: 20px;
}

.create_booster {
    display: flex;
    flex-wrap: wrap;
    gap: 19px 20px;
    margin-bottom: 30px;
}
.create_booster > * {
    width: 40%;
}

.create_booster > * {
}

.staking__rang {
    width: 212px;
}

.booster__farm-header {
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
