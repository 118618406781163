.balance {
}

.balance__icon {
    width: 122px;
    height: 35px;
}

.balance__text {
    font-family: var(--firstFont);
    fill: var(--colorText1);
    font-size: var(--fontSizeText);
    font-weight: 600;
}
