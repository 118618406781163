.searchWrap {
    width: 100%;
    background: radial-gradient(
            59.18% 59.18% at 50.73% 100.25%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
            156.26deg,
            rgba(255, 255, 255, 0.2) -4.88%,
            rgba(255, 255, 255, 0) 147.21%
        );
    backdrop-filter: blur(43.9152px);
    border-radius: 30px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    padding: 5px 22px;
}

.searchInput {
    width: 100%;
    padding: 5px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: rgba(255, 255, 255, 0.5);
}
