.wallet-card {
    padding: 10px 12px 20px;
    border-radius: 10px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    background: linear-gradient(315deg, #011c50 0%, #5e58d0 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
}

.wallet-card__title {
    font-family: var(--firstFont);
    color: var(--colorText1);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 2px 10px;
    margin-top: 13px;
}

.wallet-card__text {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: 13px;
    color: var(--colorText1);
    margin: 1px 10px;
}

.wallet-card__subtext {
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: 13px;
    color: var(--colorText4);
    margin: 1px 10px;
}

.wallet-card__wrap-btn {
    display: flex;
    justify-content: center;
    margin-top: auto;
}

.wallet-card__btn1,
.wallet-card__btn2,
.wallet-card__btn3 {
    padding: 2px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    min-width: 88px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.wallet-card__btn1:hover,
.wallet-card__btn2:hover,
.wallet-card__btn3:hover {
    transform: scale(0.98);
}

.wallet-card__btn1 {
    position: relative;
    background: linear-gradient(
        40.6889deg,
        rgba(150, 203, 252, 0.81) 0%,
        rgba(7, 67, 122, 0) 100%
    );
}

.wallet-card__btn2 {
    position: relative;
    background: linear-gradient(
        146.25deg,
        rgba(235, 55, 97, 0.8) 0%,
        rgba(170, 0, 40, 0.5) 100%
    );
}

.wallet-card__btn1 > svg,
.wallet-card__btn2 > svg {
    position: absolute;
    top: 0;
    left: 0;
}

.wallet-card__btn3 {
    background: linear-gradient(
        276.44deg,
        rgba(17, 6, 146, 1) -16.77%,
        rgba(133, 140, 165, 1) 153.38%
    );
    z-index: 1;
}

.wallet-card__btn1-text,
.wallet-card__btn2-text,
.wallet-card__btn3-text {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeSubtext);
    color: var(--colorText1);
    line-height: 1.2;
    z-index: 1;
}

.wallet-card__btn1-text {
}

.wallet-card__btn2-text {
}
