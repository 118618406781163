.verification {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.verification__message-wrap {
    margin: 60% 0px 5% 0px;
    /* border: 1px solid white; */
    width: 100%;
    text-align: center;
    padding: 40px 20px;
    background: var(--gradient3);
    border-radius: 14.0529px;
}
.verification__message {
    font-size: 24px;
    display: flex;
    justify-content: space-around;
}
.verification__message-success {
}
.verification__message-fail {
    color: tomato;
}

.verification__message {
    display: flex;
}

.verification > button {
    height: 40px;

    /* border: 1px solid white; */
}

.verification__message-text {
    font-size: larger;
}
