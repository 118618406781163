.boosterpack {
    margin-bottom: 30px;
    height: 100%;
    width: 100%;
}

.boosterpack__wrap {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.boosterpack__balance {
    display: flex;
    justify-content: space-between;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #c5c0da;
    margin-bottom: 10px;
}

.boosterpack__block {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: radial-gradient(
            59.18% 59.18% at 50.73% 100.25%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
            156.26deg,
            rgba(255, 255, 255, 0.2) -4.88%,
            rgba(255, 255, 255, 0) 147.21%
        );
    backdrop-filter: blur(43.9152px);
    border-radius: 14.0529px;
    padding-bottom: 20px;
}

.boosterpack__block > img {
    margin: 57px 0px 47px 0px;
}

.boosterpack__block__btns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
}

.boosterpack__block__btns > * {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
    height: 39px;
    width: 150px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.boosterpack__block__btns > *:last-child {
    margin-left: 5px;
}

.boosterpack__block__btns > * > svg {
    width: 113.6%;
}

.boosterpack__block__buymenu {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.boosterpack__block__buymenu > button {
}

.boosterpack__block__buymenu__count {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 14px 51px 35px 51px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.boosterpack__block__buymenu__count > p {
    background: radial-gradient(
        47.56% 47.56% at 50% 50%,
        rgba(69, 150, 247, 0.36) 0%,
        rgba(69, 150, 247, 0.178875) 50.52%,
        rgba(69, 150, 247, 0) 100%
    );
    opacity: 0.8;
    width: 53px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.boosterpack__block__buymenu__count > :nth-child(3) {
    width: 70px;
    height: 41px;
    text-decoration-line: none;
}

.boosterpack__block__btns-info {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.boosterpack__block__buymenu-price {
    margin-bottom: 14px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #c5c0da;
}

.boosterpack__block__buypackbtn {
    margin-top: 20px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-decoration-line: underline;
    color: #c5c0da;
    cursor: pointer;
}

.boosterpack__block__buymenu__buybtns {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 0 10px;
}
.boosterpack__block__buymenu__buybtns > button {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
    height: 42px;
    width: 160px;
}

.card__wrap {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    row-gap: 12px;
    column-gap: 20px;
    width: 100%;
    height: 100%;
    min-height: 613px;
}

.card_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 108px;
    background: radial-gradient(
            59.18% 59.18% at 50.73% 100.25%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
            156.26deg,
            rgba(255, 255, 255, 0.2) -4.88%,
            rgba(255, 255, 255, 0) 147.21%
        );
    border-radius: 14.0529px;
    animation: aniBox 1300ms ease-out 1;
}
.card_block > p {
    margin-top: 6px;
}
.card_block > img {
    width: 70px;
    height: 70px;
}

.boosterpack__case-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.boosterpack__case-cap {
    top: 100px;
    position: absolute;
    animation: anicap 500ms;
    animation-timing-function: ease-in-out;
}

.boosterpack__case-opened {
    top: 250px;
    position: absolute;
}

.boosterpack__case-light {
    top: 241px;
    position: absolute;
    margin-right: 12px;
    animation: anilight 4s ease-in-out;
}

.boosterpack__case-token {
    top: 250px;
    position: absolute;
    width: 89.286px;
    height: 91.76px;
    transform: rotate(20.358deg);
    animation: anitoken 4s ease-in-out;
}
.boosterpack__audio {
    opacity: 0;
    position: absolute;
    bottom: 0;
}

@keyframes anicap {
    0% {
        top: 200px;
    }
    100% {
        top: 100px;
    }
}

@keyframes anilight {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes anitoken {
    0% {
        top: 320px;
        opacity: 0;
        transform: rotate(-360deg) scale(0%);
        filter: blur(10px) brightness(10%);
    }
    60% {
        transform: rotate(0) scale(145%);
        filter: blur(10px) contrast(0%);
        opacity: 1;
    }
    85% {
        transform: rotate(0) scale(100%);
        opacity: 0.7;
        filter: brightness(0%);
    }
    90% {
        opacity: 1;
        filter: contrast(100%) brightness(300%) blur(0);
    }
    95% {
        opacity: 1;
        filter: contrast(100%) brightness(100%) blur(0);
    }
    100% {
        opacity: 1;
        filter: contrast(100%) brightness(300%) blur(0);
    }
}

@keyframes aniBox {
    0% {
        opacity: 0;
        transform: rotate(-360deg) scale(0%);
        filter: blur(10px) brightness(10%);
    }
    60% {
        transform: rotate(0) scale(145%);
        filter: blur(10px) contrast(0%);
        opacity: 1;
    }
    85% {
        transform: rotate(0) scale(100%);
        opacity: 0.7;
        filter: brightness(0%);
    }
    90% {
        opacity: 1;
        filter: contrast(100%) brightness(300%) blur(0);
    }
    95% {
        opacity: 1;
        filter: contrast(100%) brightness(100%) blur(0);
    }
    100% {
        opacity: 1;
        filter: contrast(100%) brightness(300%) blur(0);
    }
}
