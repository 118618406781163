.sign-in {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.sign-in__wrap-title {
    margin-bottom: 90px;
    margin-top: auto;
}

.sign-in__wrap-input {
    margin-bottom: 26px;
}

.sign-in__wrap-checkbox {
    margin-bottom: 40px;
}

.sign-in__wrap-btn {
    margin-bottom: 20px;
}

.sign-in__wrap-recovery,
.sign-in__wrap-sign-up {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.sign-in__wrap-sign-up {
    margin-top: auto;
}

.sign-in__sign-up {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    color: var(--colorText2);
    letter-spacing: 0.02em;
    text-align: center;
}

.agreement {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    text-indent: 5px;
    text-decoration-line: underline;
    color: #c5c0da;
}
