.main-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(315deg, #011c50 0%, #5e58d0 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    padding: 5px 15px;
    cursor: pointer;
}

.main-card:hover {
    transform: scale(0.98);
}

.main-card__wrap-icon {
    height: 100%;
    width: 100%;
    position: relative;
}

.main-card__wrap-icon > svg {
    margin: auto;
    display: block;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.main-card__title {
    font-family: var(--firstFont);
    font-weight: 600;
    /* font-size: var(--fontSizeSubTitle); */
    background: var(--gradient1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    color: var(--colorText1);
    text-align: center;
    margin-bottom: 20px;
    line-height: 1;
    white-space: normal;
    font-size: 14px;
}
