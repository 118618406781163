.input {
}

.input__wrap {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    background: var(--colorBlock2);
    border-radius: 12px;
    height: 50px;
}

.input__wrap_focus {
    border: 1px solid #4790f2;
}

.input__wrap_error {
    border: 1px solid rgba(255, 60, 0, 0.6);
}

.input__input {
    height: 20px;
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    color: var(--colorText1);
    flex: 1;
    background: transparent;
}

.input__input::placeholder {
    color: var(--colorText2);
}

.input .input__input:-webkit-autofill {
    transition:
        background-color 60000000s 0s,
        color 60000000s 0s;
}

.input__left {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input__right {
    width: 20px;
    height: 20px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input__right_clicked {
    cursor: pointer;
}

.input__error {
    padding: 8px 23px 0px;
}

.input__error-text {
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    color: rgba(255, 60, 0, 0.6);
}
