.rangbutton {
}
.marketplace__rangbutton-wrapper button {
    display: flex;
    width: 100%;
    height: 29px;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 4px;
    background: radial-gradient(
            59.18% 59.18% at 50.73% 100.25%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
            141deg,
            rgba(255, 255, 255, 0.2) 39.74%,
            rgba(255, 255, 255, 0) 100%
        );
    backdrop-filter: blur(44px);
    color: #4596f7;
    cursor: pointer;
}

.marketplace__rangbutton-wrapper > button {
    flex-direction: column;
}

.marketplace__starbuttons-wrapper {
    display: flex;
    flex-direction: column;
}

.star {
    width: 12.61px;
    height: 13.077px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.marketplace__starbuttons-wrapper > button {
    justify-content: start;
    margin-top: 2.5px;
}

.starwrapper {
    justify-content: start;
}

.marketplace__rangbutton-wrapper svg {
    position: absolute;
}

.marketplace__starchoosen {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1px 6px;
    padding-top: 4.5px;
}
