.button-outline {
    background: var(--gradient1);
    border-radius: 40px;
    width: 100%;
    padding: 1px;
    cursor: pointer;
}

.button-outline:hover {
    transform: scale(0.98);
}

.button-outline__wrap {
    border-width: 1px;
    border-radius: 40px;
    background: var(--colorBlock1);
    width: 100%;
    min-height: 52px;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-outline__text {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeBigText);
    background: var(--gradient1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    color: var(--colorText1);
    line-height: 1;
}
