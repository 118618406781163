.sharebutton__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.sharebutton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    cursor: pointer;
    border-radius: 37.333px;
    background: rgba(255, 255, 255, 0.5);
    padding-bottom: 8px;
    padding: 0;
}

.sharelabel {
    color: rgba(255, 255, 255, 0.5);
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
}

.last button {
    background: rgba(69, 150, 247, 0.08);
}

.last label {
    color: #4596f7;
}
