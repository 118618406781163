.referrals-line {
}

.referrals-line__wrap {
    background: var(--gradient3);
    border-radius: 14px;
    padding: 1px 12px 10px;
    margin-top: 30px;
}

.referrals-line__info {
    margin-top: 25px;
}

.referrals-line__info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
}

.referrals-line__info-label {
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    color: var(--colorText4);
    line-height: 1;
}

.referrals-line__info-text {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeText);
    color: var(--colorText4);
    line-height: 1;
}

.referrals-line__wrap-list {
    margin-top: 15px;
}
