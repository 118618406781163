.list {
}

.list__item {
    border-bottom: 1px solid #1c225c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3px;
    padding-top: 6px;
}

.list__item_last {
    border-bottom: none;
}

.list__item-left {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    background: linear-gradient(268.27deg, #4596f7 -1.34%, #031381 123.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    color: var(--colorText1);
    line-height: 1.1;
    width: 24%;
    line-break: anywhere;
}

.list__item-center {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    color: var(--colorText1);
    line-height: 1;
}

.list__item-right {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    color: var(--colorText4);
    line-height: 1.3;
    max-width: 24%;
    text-align: center;
}
