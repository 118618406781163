.sign-up {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.sign-up__wrap-title {
    margin-bottom: 90px;
    margin-top: auto;
}

.sign-up__wrap-input {
    margin-bottom: 26px;
}

.sign-up__wrap-checkbox {
    margin-bottom: 40px;
}

.sign-up__wrap-btn {
    margin-bottom: 35px;
}

.sign-up__wrap-sign-in {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.sign-up__wrap-sign-in {
    margin-top: auto;
}

.sign-up__sign-in {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    color: var(--colorText2);
    letter-spacing: 0.02em;
    text-align: center;
}
