.verrep__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
}

.verrep__input {
    margin-bottom: 38px;
}

.verrep__title {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #d8d5e4;
    margin-bottom: 10px;
}

.verrep__info {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.5px;
    color: rgba(216, 213, 228, 0.8);
    margin-bottom: 26px;
}
