.password-recovery {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.password-recovery__wrap {
    margin-bottom: auto;
    margin-top: auto;
}

.password-recovery__title {
    font-family: var(--firstFont);
    font-weight: 700;
    font-size: var(--fontSizeTitle);
    text-align: center;
    color: var(--colorText2);
    margin-bottom: 10px;
    text-align: center;
}

.password-recovery__text {
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    text-align: center;
    color: var(--colorText5);
    margin-bottom: 26px;
    text-align: center;
    padding: 0 10px;
}

.password-recovery__wrap-input {
    margin-bottom: 40px;
}
