.wallet__ghost {
    width: 150px;
    height: 18px;
    background-color: rgb(94, 88, 208);
    border-radius: 5px;
    margin: 0 auto;
    -webkit-animation: fadein 1s ease-in alternate infinite;
    -moz-animation: fadein 1s ease-in alternate infinite;
    animation: fadein 1s ease-in alternate infinite;
}

@keyframes fadein {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 0.3;
    }
}
