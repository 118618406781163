.header {
    display: flex;
    align-items: center;
    padding: 8px 30px 8px 0;
}

.header__btn {
    background: no-repeat;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.header__btn:hover .header__icon {
    fill: #031381;
}

.header__icon {
    width: 10px;
    height: 17px;
    fill: #5165cd;
}

.header__title {
    font-family: var(--firstFont);
    font-weight: 700;
    font-size: var(--fontSizeSubTitle);
    background: var(--gradient1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    color: var(--colorText2);
    letter-spacing: 0.02em;
    flex: 1;
    text-align: center;
    line-height: 1;
}
