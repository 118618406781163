.wallet-replenish {
}

.wallet-replenish__wrap-header {
}

.wallet-replenish__wrap-profile-bar {
    margin-top: 12px;
    margin-bottom: 20px;
}

.wallet-replenish__wrap-rate {
    margin-top: 20px;
}

.wallet-replenish__rate {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeSubtext);
    color: var(--colorText4);
    line-height: 1;
    text-align: center;
}

.wallet-replenish__wrap-info {
    margin-top: 40px;
}

.wallet-replenish__info {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeText);
    color: var(--colorText4);
    line-height: 1.3;
    text-align: center;
}
