.staking {
}

.staking__rang-wrap {
    display: flex;
    justify-content: center;
    margin-top: 27px;
    gap: 15px;
    width: 100%;
}

.staking__wrap {
    background: var(--gradient3);
    border-radius: 14px;
    padding: 20px 12px 20px;
    margin-top: 10px;
}

.staking__line {
    width: 100%;
    height: 1px;
    background: rgba(197, 192, 218, 0.2);
    margin-bottom: 18px;
}

.staking__info-title {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    color: var(--colorText1);
    line-height: 1;
    text-align: center;
    margin: 7px;
}

.staking__info-count {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: 40px;
    color: var(--colorText1);
    line-height: 1;
    text-align: center;
    margin: 7px;
    margin-top: 30px;
}

.staking__wrap-balance {
    background: var(--gradient3);
    border-radius: 14px;
    padding: 20px 12px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.staking__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.staking__btns > button {
    position: relative;
    width: 160px;
    height: 43px;
    cursor: pointer;
}
.staking__btns > button svg {
    width: 113.6%;
}

.staking__btns > button:last-child {
    margin-left: 5px;
}

.staking__input-wrap {
    position: relative;
}

.staking__input-wrap > span {
    position: absolute;
    right: 17px;
    top: 5px;
}

.staking__input {
    background: rgba(197, 192, 218, 0.8);
    border-radius: 4px;
    height: 30px;
    color: var(--colorText1);
    width: 100%;
    height: 30px;
    padding: 15px;
    font-size: var(--fontSizeSubtext);
    -webkit-appearance: none;
    margin: 0;
    margin-bottom: 10px;
}

.staking__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.staking__input::placeholder {
    color: var(--colorText1);
}

.staking__input-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: var(--fontSizeSubTitle);
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.5px;
    color: var(--colorText1);
    margin-bottom: 20px;
}

.staking__input-btns {
    display: flex;
    justify-content: space-between;
}

.staking__input-btns > * {
    height: 42px;
    margin: 15px 0px;
}

.staking__input__maxbtn {
    cursor: pointer;
}

.staking__freeze-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.staking__freeze-list-item-item {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}

.staking__freeze-list-item-sumwrap {
    display: flex;
    gap: 20px;
}

.staking__freeze-list-item-item-sun {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    /* светлый цвет для шрифта */

    color: #c5c0da;
}

.staking__freeze-list-item-date {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #c5c0da;
}

.staking__freeze-list-item-take {
    color: white;
    padding: 5px 0;
    text-align: center;
    width: 117px;
    background: linear-gradient(270deg, #4596f7 -2.9%, #031381 102.74%);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    cursor: pointer;
}
