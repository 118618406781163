.profile {
}

.profile__wrap-profile-block {
    margin-top: 35px;
}

.profile__wrap-input {
    margin-bottom: 20px;
}

.profile__label {
    padding-left: 20px;
    padding-bottom: 10px;
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    color: var(--colorText1);
    line-height: 1;
}

.profile__wrap-btn {
    margin-top: 40px;
    margin-bottom: 16px;
}
