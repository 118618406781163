.password-reset-success {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
}

.password-reset-success__wrap {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

.password-reset-success__shine {
    position: absolute;
    left: -55px;
    right: -55px;
    top: -90px;
    bottom: -90px;
    z-index: -1;
    background: radial-gradient(
        47.56% 47.56% at 50% 50%,
        rgba(0, 103, 199, 0.45) 0%,
        rgba(45, 140, 227, 0.14) 50.52%,
        rgba(89, 175, 255, 0) 100%
    );
}

.password-reset-success__img {
    width: 120px;
    height: 120px;
    margin-bottom: 40px;
}

.password-reset-success__text {
    font-family: var(--firstFont);
    font-weight: 700;
    font-size: var(--fontSizeTitle);
    color: var(--colorText2);
    text-align: center;
}
