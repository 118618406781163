.rang {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    background: var(--gradient3);
    border-radius: 14px;
    padding: 16px 0px 12px;
    justify-content: space-between;
    flex: auto;
    max-width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.rang__text {
    text-align: center;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    background: linear-gradient(268.82deg, #4596f7 -1.77%, #031381 200.73%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.rang__start {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 22px;
    padding: 0;
}

.rang__startbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.rang__img-wrapper {
    width: 111.981px;
    height: 111.981px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rang__img-wrapper > img {
    position: absolute;
}

.rang__startbutton > h5 {
    color: var(--unnamed, #c5c0da);
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.rang__stars {
    display: flex;
}

.rang__star {
    background: radial-gradient(
        47.56% 47.56% at 50% 50%,
        rgba(0, 103, 199, 0.45) 0%,
        rgba(45, 140, 227, 0.223594) 50.52%,
        rgba(89, 175, 255, 0) 100%
    );
}

.rang__power {
    display: flex;
    gap: 10px;
    margin: 14px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #c5c0da;
}

.rang__power-text {
    background: linear-gradient(
        158.15deg,
        #ead411 -20.36%,
        rgba(181, 140, 36, 0) 214.02%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.rang__sale {
    position: absolute;
    width: 51px;
    height: 47px;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rang__text {
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sale {
    position: absolute;
    bottom: 0;
    right: 0;
}

.sale__text {
    text-align: center;
    transform: rotate(-45.166deg);
    margin-top: 13px;
    margin-left: 10px;
}

.sale__text-big {
    transform: rotate(-45.422deg);
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 40px;
    margin-right: 50px;
}
