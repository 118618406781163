.button-filled {
    background: var(--gradient1);
    width: 100%;
    height: 52px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    cursor: pointer;
}

.button-filled:not(.button-filled_disabled):hover {
    transform: scale(0.98);
}

.button-filled_disabled {
    background: var(--colorBlock2);
    cursor: default;
}

.button-filled__text {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeBigText);
    color: var(--colorText1);
    line-height: 1;
}
