.main {
}

.main__label {
    display: flex;
    height: 34.598px;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.main__wrap-balance {
    display: flex;
    justify-content: flex-end;
    z-index: 3;
}

.main__wrap-profile-bar {
    margin-top: -6px;
}

.main__wrap-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px;
}

.main__wrap-card {
}

.main__tokens {
    padding: 0;
}
