.stats {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    min-width: 110px;
}

.stats__wrap {
    background: var(--gradient3);
    border-radius: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 6px 8.2px 7px;
}
.stats__text {
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    line-height: 1;
    margin: 0;
    margin-bottom: 3px;
}
.stats__value-wrap {
    display: flex;
    column-gap: 4px;
    padding: 0;
    cursor: pointer;
}
.stats__text-value {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    background: linear-gradient(
        158.15deg,
        #ead411 -20.36%,
        rgba(181, 140, 36, 0) 214.02%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0;
}

.stats__price-value {
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
