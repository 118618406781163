.referrals-profile-bar {
    display: flex;
    align-items: center;
    background: var(--gradient3);
    border-radius: 14px;
    padding: 10px 15px 4px 7px;
    overflow: hidden;
}

.referrals-profile-bar__left {
    margin-right: 20px;
    position: relative;
}

.referrals-profile-bar__left-shine {
    position: absolute;
    z-index: -1;
    width: 230px;
    height: 230px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(
        47.56% 47.56% at 50% 50%,
        rgba(0, 103, 199, 0.45) 0%,
        rgba(45, 140, 227, 0.14) 50.52%,
        rgba(89, 175, 255, 0) 100%
    );
}

.referrals-profile-bar__left-icon {
    width: 115px;
    height: 115px;
}

.referrals-profile-bar__right {
    flex: 1;
}

.referrals-profile-bar__name {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    color: var(--colorText1);
    margin-bottom: 5px;
    line-height: 1;
}

.referrals-profile-bar__tag {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeText);
    background: linear-gradient(268.27deg, #4596f7 -1.34%, #031381 123.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    color: var(--colorText1);
    line-height: 1.1;
    margin-bottom: 8px;
    width: min-content;
}

.referrals-profile-bar__line {
    width: 100%;
    height: 1px;
    background: rgba(197, 192, 218, 0.2);
    margin-bottom: 10px;
}
