.checkbox {
    display: flex;
}

.checkbox__wrap {
    width: 20px;
    height: 20px;
    background: var(--colorBlock1);
    border: 1px solid var(--colorBlock2);
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox__wrap_check {
    background: #433f6d;
    border-radius: 6px;
}

.checkbox__check {
    width: 14px;
    height: 10px;
    fill: var(--colorText2);
}

.checkbox__text {
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    color: var(--colorText4);
    margin-left: 6px;
}
