.marketplace__filter-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
    padding-bottom: 4.5px;
    padding-top: 20px;
}

.marketplace__main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.marketplace__cards-wrapper {
    width: 100%;
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 11px;
    align-self: center;
}
.marketplace {
    padding: 0;
}

.marketplace__modal-contentwrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.marketplace__modal-rang {
    margin-bottom: 9px;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(227deg, #4596f7 0.06%, #031381 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.marketplace__modal-cardprice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 34px;
    margin: 8px 0px;
}
.marketplace__modal-cardprice p {
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.marketplace__modal-img {
    width: 150px;
    height: 150px;
    margin-bottom: 9px;
}

.marketplace__history-link {
    position: absolute;
    width: 24.338px;
    height: 24.338px;
    top: 70px;
    right: 22.4px;
}
