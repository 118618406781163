.modal {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border: 0;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    padding: 20px;
    opacity: 1;

    justify-content: center;
    -webkit-animation: fadein 500ms ease-in alternate 1;
    -moz-animation: fadein 500ms ease-in alternate 1;
    animation: fadein 500ms ease-in alternate 1;
    backdrop-filter: blur(15.9152px);
}

.modal__black {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 1;
}

.modal__closebtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}

.modal__message {
    width: 100%;
    z-index: 3;
    padding: 32px 10px;
    background: var(--gradient3);
    border-radius: 14.0529px;
    position: relative;
}

.modal__btns-block {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}
.modal__btns-block > * {
    height: 42px;
    width: 161px;
}

.modal__btns-block button {
    height: 42px;
    width: 161px;
}

.modal__btns-block button > svg {
    width: 113.6%;
}

.modal__btns-block button:last-child {
    margin-left: 5px;
}

.modal__bottomspan p {
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 21px;
}

@keyframes fadein {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 0.9;
    }
}
.title {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    color: white;
}
.buttons-wrap {
    display: flex;
    justify-content: space-between;
}
/* .buttons-wrap>button{
    width: 48%;
} */
.input-wrap {
    position: relative;
    display: flex;
}
.input-img {
    position: absolute;
    right: 5px;
}
.modal-input {
    width: 100%;
    background: rgba(197, 192, 218, 0.8);
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.8);
    padding: 7px 12px;
    margin-bottom: 14px;
}
.modal-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.withdrawal-course {
    width: 100%;
    text-align: left;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.7);
}
