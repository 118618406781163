.referrals {
}

.referrals__wrap-referrals-profile-bar {
    margin-top: 33px;
}

.referrals__wrap {
    background: var(--gradient3);
    border-radius: 14px;
    padding: 20px 10px 20px;
    margin-top: 10px;
}

.referrals__title {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    color: var(--colorText1);
    line-height: 1;
    text-align: center;
}

.referrals__wrap-link {
    margin-top: 17px;
    display: flex;
    gap: 4px;
}

.referrals__wrap-link-copy {
    /*margin-right: 4px;*/
    flex: 1;
}

.referrals__wrap-link-share {
}

.referrals__info {
    margin-top: 25px;
}

.referrals__info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
}

.referrals__info-label {
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    color: var(--colorText4);
    line-height: 1;
}

.referrals__info-text {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeText);
    color: var(--colorText4);
    line-height: 1;
}

.referrals__line {
    width: 100%;
    height: 1px;
    background: rgba(197, 192, 218, 0.2);
    margin-bottom: 18px;
}

.referrals__lines {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.referrals__wrap-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.referrals__wrap-btn > button {
    width: 150px;
    height: 39.6px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
}

.referrals__wrap-history {
    display: flex;
    justify-content: center;
    margin-top: 23px;
}

.referrals__history {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeSubtext);
    color: var(--colorText4);
    line-height: 1;
    text-decoration: underline;
}

.sharebuttons__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sharebuttons__label {
    color: #fff;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 20px;
    padding-left: 5px;
}
