.profile-bar {
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.profile-bar__left {
    margin-right: 12px;
    position: relative;
}

.profile-bar__left-shine {
    position: absolute;
    z-index: -1;
    width: 230px;
    height: 230px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(
        47.56% 47.56% at 50% 50%,
        rgba(0, 103, 199, 0.45) 0%,
        rgba(45, 140, 227, 0.14) 50.52%,
        rgba(89, 175, 255, 0) 100%
    );
}

.profile-bar__left-icon {
    width: 140px;
    height: 140px;
}

.profile-bar__right {
    flex: 1;
    padding-bottom: 10px;
}

.profile-bar__name {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    color: var(--colorText1);
    margin-bottom: 5px;
    line-height: 1;
}

.profile-bar__tag {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeText);
    background: linear-gradient(268.27deg, #4596f7 -1.34%, #031381 123.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    color: var(--colorText1);
    line-height: 1;
    margin-bottom: 8px;
    display: inline-block;
}

.profile-bar__wrap-balance {
    display: flex;
    align-items: center;
}

.profile-bar__balance {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    background: linear-gradient(
        158.15deg,
        #ead411 -20.36%,
        rgba(181, 140, 36, 0) 214.02%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    color: var(--colorText1);
    line-height: 1;
    margin-left: 5px;
}
