.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.loader__wrap {
    width: 100px;
    height: 100px;
    background: #0d0d0d;
    border-radius: 20px;
    padding: 20px;
}

.loader__icon {
    fill: var(--colorText2);
}
