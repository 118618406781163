/*.modal-wallet{
	top: 50%;
	left: 50%;
	width: 90%;
	max-width: 1200px;
	transform: translate(-50%, -50%);
	background: var(--colorGradient1);
	border-radius: 30px;
	border: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
	opacity: 1;
	transition: opacity 1s ease;
}*/

.modal-wallet {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--colorGradient1);
    border-radius: 30px;
    border: 0;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    opacity: 1;
    transition: opacity 1s ease;
    justify-content: center;
}

.modal-wallet[open] {
    background: rgba(15, 2, 52, 0.4);
    backdrop-filter: blur(5px);
}
.modal-wallet__title {
    font-family: var(--firstFont);
    font-weight: 700;
    font-size: var(--fontSizeTitle);
    line-height: 1.3;
    color: var(--colorText1);
    text-align: center;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}
.modal-wallet__text {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeBigText);
    line-height: 1.3;
    color: var(--colorText4);
    text-align: center;
    margin-top: 40px;
    max-width: 500px;
}
.modal-wallet__wrap {
    background: radial-gradient(
            59.18% 59.18% at 50.73% 100.25%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
            156.26deg,
            rgba(255, 255, 255, 0.2) -4.88%,
            rgba(255, 255, 255, 0) 147.21%
        ),
        #3e437a;
    border-radius: 14px;
    padding: 32px 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 500px;
}
.modal-wallet__input {
    width: 100%;
    padding: 6px 12px;
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeSubtext);
    line-height: 1.3;
    color: var(--colorText1);
    border: 1px solid transparent;
    background: rgba(197, 192, 218, 0.8);
    border-radius: 4px;
}
.modal-wallet__input::placeholder {
    opacity: 0.5;
    color: var(--colorText1);
}
.modal-wallet__input[error] {
    border-color: var(--colorTextError);
}
.modal-wallet__wrap-btns {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
}
.modal-wallet__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 7px 20px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    max-width: 160px;
    min-height: 43px;
    cursor: pointer;
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    line-height: 1.3;
    color: var(--colorText1);
}
.modal-wallet__btn_cancel {
    background: linear-gradient(
        267.4deg,
        #eb3761 -19.19%,
        rgba(170, 0, 40, 1) 162.41%
    );
}

.modal-wallet__btn_confirm {
    background: linear-gradient(
        270deg,
        #4596f7 -2.9%,
        rgba(3, 19, 129, 1) 102.74%
    );
}
.modal-wallet__btn:hover {
    opacity: 0.9;
}
.modal-wallet:not([open]) {
    z-index: -100;
    opacity: 0;
    transition: opacity 0s ease;
}
