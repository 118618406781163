.wallet-card__logo {
    align-self: center;
    width: 175px;
    height: 37px;
}

.wallet-card__logo_repl {
    position: absolute;
    top: 90px;
    left: 30px;
    width: 91px;
    height: 16px;
}
.wallet-card__logo2 {
    position: absolute;
    top: 76px;
    left: 12px;
    width: 175px;
    height: 37px;
}
.wallet-card__side {
    position: absolute;
    top: 0;
    right: 0;
    height: 80%;
}
