.collection__card-wrapper {
}

.collection__slider__block {
    height: 500px;
    color: #fff;
    font-family: Source Sans Pro;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.collection__slider__block .swiper .swiper-pagination {
    top: 0;
}
