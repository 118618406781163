.wallet {
    padding-top: 6px;
}

.wallet__wrap-header {
}

.wallet__wrap-profile-bar {
    margin-top: 12px;
    margin-bottom: 20px;
}

.wallet__wrap-rate {
    margin: 10px 0px;
}

.wallet__rate {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeSubtext);
    color: var(--colorText4);
    line-height: 1;
    text-align: center;
}

.wallet__work {
    margin-bottom: 22px;
    background: radial-gradient(
            59.18% 59.18% at 50.73% 100.25%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
            156.26deg,
            rgba(255, 255, 255, 0.2) -4.88%,
            rgba(255, 255, 255, 0) 147.21%
        );
    backdrop-filter: blur(43.9152px);
    border-radius: 14.0529px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 20px;
}

.wallet__switcher {
    background-color: white;
    height: 10px;
    width: 20px;
    cursor: pointer;
}
