.button-share {
    padding: 6px 6px 3px;
    background: rgba(197, 192, 218, 0.8);
    border-radius: 4px;
    cursor: pointer;
}

.button-share:hover {
    background: rgba(197, 192, 218, 0.5);
}

.button-share > svg {
    width: 18px;
    height: 18px;
    fill: var(--colorText1);
}
