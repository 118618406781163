.button-filled-min {
    width: 100%;
    min-height: 31.5px;
    width: 149.5px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
}

.button-filled-min > svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.button-filled-min:not(.button-filled-min_disabled):hover {
    transform: scale(0.98);
}

.button-filled-min_disabled {
    cursor: default;
}

.button-filled-min__text {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeBigText);
    color: white;
    line-height: 1;
    z-index: 1;
}
