.wrapper {
    /* border:1px solid white; */
    /* height: 100%; */
    width: 100%;
    /* display: flex;
    flex-direction: column; */

    /* align-self: stretch; */
}
.sliderTopWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 287px;
}

.gamesSwiper {
    width: 100%;
    height: 187px;
    padding: 0;
}

.gameSlide {
    background: white;
    width: 100%;
    height: 100%;
}
.bannerImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.sliderBottomWrapper {
    height: 100%;

    position: relative;
}

.searchSwiper {
    width: 100%;
    height: 100%;
}

/* .gameSlide{
    width: 100%;
    height: 240px;
    background: tomato;
    border-radius: 14px;
} */

.serchFindNoneWrap {
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.serchFindNone {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.5px;

    color: #ffffff;
}

.gameBlock {
    height: 200px;
    /* border: 1px solid white; */
    width: 100%;
    height: 270px;

    display: flex;
    flex-direction: column;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 10px;
}

.gameInfoBlock {
    display: flex;
    justify-content: space-between;
    padding: 11px 20px;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 0 0 14px 14px;
}

.gameBlockImg {
    width: 100%;
    height: 200px;
    object-fit: cover;
    z-index: 0;
}

.gameInfoName {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-bottom: 8px;
}

.gameInfoDescription {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #c5c0da;
}
.gameBtnWrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gamePlayBtn {
    background: linear-gradient(270deg, #4596f7 -2.9%, #031381 102.74%);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 5px 22px;
}

.swiper-pagination {
    position: absolute !important;
}
