.bottom-bar {
    --bottom-bar-height: 80px;
    height: var(--bottom-bar-height);
    width: 100%;
    z-index: 99999 !important;
}
.bottom-bar__wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: var(--bottom-bar-height);
    padding-bottom: 5px;
    background: var(--colorBlock1);
}

.bottom-bar__wrap-inner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 300px;
    width: 100%;
    height: var(--bottom-bar-height);
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
}

.bottom-bar__center {
}

.bottom-bar__center-img {
    width: 60px;
    height: 60px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.bottom-bar__center-shine {
    width: 80px;
    height: 80px;
    top: 50%;
}

.bottom-bar__left-shine,
.bottom-bar__right-shine {
    width: 60px;
    height: 60px;
    top: 47%;
}

.bottom-bar__left-shine,
.bottom-bar__center-shine,
.bottom-bar__right-shine {
    display: none;
    position: absolute;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(
        47.56% 47.56% at 50% 50%,
        rgba(0, 103, 199, 0.45) 0%,
        rgba(45, 140, 227, 0.223594) 50.52%,
        rgba(89, 175, 255, 0) 100%
    );
}

.bottom-bar__left_active .bottom-bar__left-shine,
.bottom-bar__center_active .bottom-bar__center-shine,
.bottom-bar__right_active .bottom-bar__right-shine {
    display: block;
}

.bottom-bar__left,
.bottom-bar__center,
.bottom-bar__right {
    position: relative;
    cursor: pointer;
}

.bottom-bar__left:hover,
.bottom-bar__center:hover,
.bottom-bar__right:hover {
    transform: scale(0.95);
}

.bottom-bar__left-icon,
.bottom-bar__right-icon {
    width: 24px;
    height: 24px;
    fill: var(--colorBottomBar);
}

@media (max-width: 480px) {
    .bottom-bar__wrap_unfixed {
        position: initial;
    }
}
