.marketplace__card-wrapper {
    width: 100%;
    border-radius: 14.053px;
    background: radial-gradient(
            59.18% 59.18% at 50.73% 100.25%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
            141deg,
            rgba(255, 255, 255, 0.2) 39.74%,
            rgba(255, 255, 255, 0) 100%
        );
    backdrop-filter: blur(43.91516876220703px);
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.marketplace__rang-img {
    width: 87.863px;
    height: 87.863px;
}

.marketplace__stars-wrapper {
    display: flex;
    flex-direction: row;
}

.marketplace__stars-wrapper div {
    height: 23px;
    width: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.marketplace__stars-wrapper img {
    width: 130%;
    height: 130%;
}

.marketplace__card-price {
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.marketplace__cardbutton-wrapper button {
    width: 74.386px;
    height: 24.297px;
    cursor: pointer;
    position: relative;
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* fill: var(--unnamed, linear-gradient(270deg, #4596F7 0.06%, #031381 100%));
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25)); */
}

.marketplace__cardbutton-wrapper svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
