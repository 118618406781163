.container {
    padding: 20px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    position: relative;
    overflow-x: hidden;
}
