.password-reset {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.password-reset__wrap {
    margin-bottom: auto;
    margin-top: auto;
}

.password-reset__wrap-input {
    margin-bottom: 26px;
}

.password-reset__wrap-btn {
    margin-top: 40px;
}
