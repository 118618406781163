.introduction {
    padding-bottom: 20px;
    padding-top: 85px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    flex: 1;
}

.introduction__wrap-logo {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 138px;
    margin-left: auto;
    margin-right: auto;
}

.introduction__wrap-logo > img {
    width: 195px;
}

.introduction__shine {
    position: absolute;
    left: -55px;
    right: -55px;
    top: -40px;
    bottom: -45px;
    z-index: -1;
    background: radial-gradient(
        47.56% 47.56% at 50% 50%,
        rgba(0, 103, 199, 0.45) 0%,
        rgba(45, 140, 227, 0.14) 50.52%,
        rgba(89, 175, 255, 0) 100%
    );
}

.introduction__logo {
    margin-bottom: 3px;
}

.introduction__logo-text {
}

.introduction__wrap-btn {
    margin-bottom: 16px;
}
