.wallet-replenish-card {
    padding: 0px 21px 21px 12px;
    border-radius: 10px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wallet-replenish-card__title {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeBigText);
    color: var(--colorText1);
    margin-bottom: 11px;
    line-height: 1;
}

.wallet-replenish-card__qr {
    width: 110px;
    height: 110px;
    object-fit: contain;
    margin-bottom: 10px;
}

.wallet-replenish-card__copy {
    display: flex;
    cursor: pointer;
}

.wallet-replenish-card__copy:hover {
    transform: scale(1.02);
}

.wallet-replenish-card__copy-icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
    fill: var(--colorText1);
}

.wallet-replenish-card__copy-text {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeSubtext);
    color: var(--colorText1);
    line-height: 1.1;
}
