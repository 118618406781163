.alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(15, 2, 52, 0.4);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    padding: 10px;
}

.alert__wrap {
    background: radial-gradient(
            59.18% 59.18% at 50.73% 100.25%,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
            156.26deg,
            rgba(255, 255, 255, 0.2) -4.88%,
            rgba(255, 255, 255, 0) 147.21%
        );
    border-radius: 14px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-width: 330px;
    width: 100%;
}

.alert__close {
    margin-left: auto;
    cursor: pointer;
}

.alert__inner {
    margin: auto;
    padding: 5px 0;
}

.alert__title {
    font-family: var(--firstFont);
    font-weight: 700;
    font-size: var(--fontSizeTitle);
    color: var(--colorText1);
    line-height: 1;
    text-align: center;
    margin-bottom: 10px;
}

.alert__text {
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    color: rgba(216, 213, 228, 0.8);
    text-align: center;
    max-width: 300px;
    letter-spacing: 0.5px;
    line-height: var(--fontSizeBigText);
}

.alert__btns-block {
    width: 100%;
    margin-top: 7px;
    max-width: 330px;
    display: flex;
    flex-direction: column;
}

.alert__btns-block button {
    color: var(--action-primary-inverted, #fff);
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
    letter-spacing: 0.36px;
    width: 100%;
    height: 52px;
    margin-bottom: 11px;
    cursor: pointer;
    border-radius: 40px;
    background: var(
        --unnamed,
        linear-gradient(270deg, #4596f7 0.06%, #031381 100%)
    );
}
