.button-referral {
    padding: 6px 12px;
    background: rgba(197, 192, 218, 0.8);
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.button-referral:hover {
    background: rgba(197, 192, 218, 0.5);
}

.button-referral__wrap {
    position: relative;
    flex: 1;
    overflow: hidden;
    height: 18px;
    display: flex;
    align-items: center;
}

.button-referral__text {
    position: absolute;
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: 11px;
    color: var(--colorText1);
    line-height: 1;
    text-decoration: underline;
}

.button-referral > svg {
    width: 18px;
    height: 18px;
    fill: var(--colorText1);
    margin-left: 10px;
}
