.history__item {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.history__item-logo img {
    width: 40px;
    height: 40px;
}

.history__starswrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.history__starswrapper svg {
    position: absolute;
    height: 14.5px;
    width: 14.5px;
}

.history__starswrapper div {
    height: 8px;
    width: 8px;
}

.history__item-date {
    min-width: 74px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.history__item-price {
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-width: 90px;
    text-align: center;
}

.history__item-action {
    color: #fff;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
