.agreement__wrap {
    height: 100%;
}
.agreement__wrap-right {
    text-align: right;
    color: bisque;
}
.agreement__wrap-middle {
    text-align: center;
    font-size: large;
    font-weight: 600;
    color: white;
}
p {
    margin: 5px 0;
    color: rgb(182, 178, 178);
}
.select {
    color: white;
}
