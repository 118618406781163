.link-text {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeText);
    color: var(--colorText3);
    letter-spacing: 0.02em;
    text-decoration: underline;
    line-height: 1.5;
}

.link-text:hover {
    text-decoration: none;
}
